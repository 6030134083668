export const KB_EN = {
	nurseryMgmt: 'Nursery Management',
	cropMgmt: 'Crop Management',
	pestMgmt: 'Pest Management',
	diseaseMgmt: 'Disease Management',
	harvestMgmt: 'Harvest Management',
	parasiteMgmt: 'Parasite Management',
	vaccine: 'Vaccine',
	landPreparation: 'Land Preparation',
	seedPreparation: 'Seed Preparation',
	transplantTechnique: 'Transplant Technique',
	remarks: 'Remarks',
	irrigation: 'Irrigation',
	interCropping: 'Inter Cropping',
	maintenance: 'Maintenance',
	support: 'Support',
	fertilizerApplication: 'Fertilizer Application',
	offSeasonCultivationTechnique: 'Off Season Cultivation Technique',
	Name: 'Name',
	identificationMethod: 'Identification Method',
	identificationTechniques: 'Identification Techniques',

	techniques: 'Techniques',
	technique: 'Technique',

	postHarvestHandling: 'Post Harvest Handling',
	type: 'Type',
	preventiveMeasure: 'Preventive Measure',
	symptoms: 'Symptoms',
	vaccineAgainst: 'Vaccine Against',
	ageFirstVaccination: 'Age First Vaccination',
	dose: 'Dose',
	boosterDose: 'Booster Dose',
	frequency: 'Frequency',
	vaccinationPeriod: 'Vaccination Period',
	vaccinationMethod: 'Vaccination Method',
	details: 'Details',
	nurseryPrep: 'Nursery Preparation'
};
