export const KB_NP = {
	nurseryMgmt: 'नर्सरी व्यवस्थापन',
	cropMgmt: 'बाली व्यवस्थापन',
	pestMgmt: 'कीरा व्यवस्थापन',
	diseaseMgmt: 'रोग व्यवस्थापन',
	harvestMgmt: 'बाली व्यवस्थापन',
	parasiteMgmt: 'parasite management',
	vaccine: 'vaccine',
	landPreparation: 'जग्गा तयारी',
	seedPreparation: 'बेर्ना तयारी प्रविधी',
	transplantTechnique: 'बिरुवा रोप्ने प्रविधि',
	remarks: 'टिप्पणीहरु ',
	irrigation: 'सिचांइ ',
	interCropping: 'अन्तरबाली',
	maintenance: 'मर्मत',
	support: 'बाली सहयाेग',
	fertilizerApplication: 'मल तथा खाधकाे प्रयाेग',
	offSeasonCultivationTechnique: 'बेमौसमी खेति प्रबिधि',
	Name: 'नाम',
	identificationMethod: 'क्षती को पहीचान',
	identificationTechniques: 'क्षती को पहीचान',
	techniques: 'प्रविधि',
	technique: 'प्रविधि',
	postHarvestHandling: 'बाली भित्राइसके पछिकाे व्यवस्थापन',
	type: 'type',
	preventiveMeasure: 'preventiveMeasure',
	symptoms: 'symptoms',
	vaccineAgainst: 'vaccineAgainst',
	ageFirstVaccination: 'ageFirstVaccination',
	dose: 'dose',
	boosterDose: 'boosterDose',
	frequency: 'frequency',
	vaccinationPeriod: 'vaccinationPeriod',
	vaccinationMethod: 'vaccinationMethod',
	details: 'details',
	nurseryPrep: 'नर्सरी तयार गर्ने '
};
