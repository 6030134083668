export const ACTIVITY_EN = {
	Activities: 'Activities',
	'Activity Questionare': 'Activity Questionare',
	General: 'General',
	Recurring: 'Recurring',
	'Submit Answers': 'Submit Answers',
	'Complete Phase': 'Complete Phase',
	'Edit Activity': 'Edit Activity',
	'No Recurring Activities': 'No Recurring Activities',
	'Upload Picture': 'Upload Picture',
	'Select file to upload': 'Select file to upload',
	'Take a Picture': 'Take a picture',
	Browse: 'Browse',
	'Skip Upload': 'Skip Upload'
};
