import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { PRODUCT_EN, COMMON_EN, PROFILE_EN, SETTING_EN, KB_EN, BATCH_EN, ACTIVITY_EN } from './en';
import { PRODUCT_NP, COMMON_NP, PROFILE_NP, SETTING_NP, KB_NP, BATCH_NP, ACTIVITY_NP } from './np';

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		},
		resources: {
			en: {
				product: PRODUCT_EN,
				common: COMMON_EN,
				profile: PROFILE_EN,
				setting: SETTING_EN,
				kb: KB_EN,
				batch: BATCH_EN,
				activity: ACTIVITY_EN
			},
			np: {
				product: PRODUCT_NP,
				common: COMMON_NP,
				profile: PROFILE_NP,
				setting: SETTING_NP,
				kb: KB_NP,
				batch: BATCH_NP,
				activity: ACTIVITY_NP
			}
		},
		defaultNS: 'common'
	});

export default i18n;
