export const BATCH_NP = {
	Name: 'नाम',
	Date: 'मिति',
	Phase: 'चरण',
	'Batch Name': 'ब्याच नाम',
	'Batch ID': 'ब्याच आईडी',
	'Farm ID': 'फार्म आईडी',
	'Show Details': 'विवरण देखाऊ',
	'Basic Details': 'आधारभूत विवरण',
	Preview: 'पूर्वावलोकन',
	'Nursery Practises': 'नर्सरी अभ्यास',
	'Land Preparation': 'जग्गा तयारी',
	'Seedling Transplantaion': 'बिरुवा प्रत्यारोपण',
	Harvesting: 'कटाई',
	'Storage And Processing': 'भण्डारण',
	Distribution: 'वितरण',
	'Product Activities (Phases)': 'उत्पादन गतिविधिहरु (चरणहरु)',
	Plot: 'प्लट',
	'Batch Detail': 'ब्याच  विवरण',
	'Product Details': 'उत्पादन  विवरण',
	'Batch Info': 'ब्याचको जानकारी',
	'Advanced Info': 'थप जानकारी',
	'Stopped Phase': 'गतिविधि रोकिएको चरण',
	Sold: 'बिक्री',
	Removed: 'हटाउन',
	Damaged: 'क्षति भएर',
	Others: 'अन्य',
	'Archive Confirm Text': 'के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ यो ब्याच अभिलेख गर्न चाहनुहुन्छ?',
	'Unarchive Confirm Text': 'के तपाइँ यो ब्याच पुन: सक्रिय गर्न निश्चित हुनुहुन्छ?',
	Confirm: 'सावधान',
	Yes: 'छु',
	Cancel: 'छैन',
	'Archive Reason': 'अभिलेख गर्नुको कारण'
};
