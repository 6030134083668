export const COMMON_NP = {
	'My Products': 'मेरो उत्पादन',
	'My Crops': 'मेरो बालीहरु',
	'Transfered Batches': 'सुम्पिएको बाली',
	'No batch added': 'कुनै उत्पादनहरु थपिएको छैन',
	Product: 'उत्पादन',
	Knowledgebase: 'ज्ञानको आधार',
	weather: 'मौसम',
	Settings: 'सेटिंग्स',
	form: {
		Submit: 'बुझाउनु हाेस्',
		Save: 'भण्डार गर्नुहोस्',
		Cancel: 'रद्द गर्नुहोस्'
	},
	productCategory: {
		Flora: 'बाली',
		Fauna: 'पशुपालन',
		Plant: 'बिरुवा',
		Cereal: 'अन्न बाली',
		Legume: 'दलहन बाली',
		Vegetables: 'तरकारी बाली',
		Vegetable: 'तरकारी',
		Spice: 'Spice',
		Forage: 'Forage',
		'Medicinal And Aromatic': 'Medicinal And Aromatic',

		Fruit: 'फलफूल बाली',
		'Oil Seed Crop': 'तेलहन बाली ',
		Beverage: 'कफी तथा चिया बाली',
		'Feed and forage': 'घाँस बाली',
		Fibre: 'तन्तु / फाइबर बाली',
		Ornamental: 'आलंकारिक बागवानी',
		Other: 'अन्य बाली',
		Others: 'अन्य बाली ',
		'Small Ruminants': 'सानो रोमिनेन्ट',
		'Large Ruminants': 'ठूलो  रोमिनेन्ट',
		'Large Ruminant': 'ठूलो  रोमिनेन्ट',
		'Small Ruminant': 'सानो रोमिनेन्ट',

		Animal: 'पशुपालन',
		Pig: 'सुँगुरहरु',
		Poultry: 'कुखुराहरु',
		Equine: 'बराबर',
		Camel: 'ऊँटहरु',
		Fish: 'माछाहरु',
		Insect: 'किराहरु',
		'No info': 'विवरण छैन ',
		'No products': 'उत्पादन छैन',
		Electronics: 'इलेक्ट्रोनिक्स',
		Textile: 'कपडा',
		Cosmetic: 'कस्मेटिक',
		'Skin Care': 'छाला हेरचाह',
		Health: 'स्वास्थ्य',
		Personal_Care_and_Wellness: 'व्यक्तिगत हेरचाह'
	}
};
