export const SETTING_NP = {
	'IPFS upload URL': 'IPFS अपलोड URL',
	'Enter full IPFS url': 'पूरा IPFS url प्रविष्ट गर्नुहोस् ',
	'IPFS download url': 'URL बाट IPFS डाउनलोड गर्नुहोस् ।',
	'Enter IPFS download url': 'IPFS डाउनलोड गर्न url प्रविष्ट गर्नुहोस्',
	'Connect with Google': 'गुगलसँग जोडीनुहोस्',
	'Connect to Google Drive': 'गुगल ड्राइवमा जोडीनुहोस्',
	'Privacy Policy': 'गोपनीयता नीति',
	'Continue with your Google Account': 'आफ्नो गूगल खातासंग जारी राख्नुहोस्।',
	'Please choose Google account. Please click the switch account button to change account':
		'कृपया आफ्नो गूगल खाता छान्नुहोस्। कृपया खाता परिवर्तन गर्न स्विच खाता बटन थिच्नुहोस् ।',
	'Loading User…': 'प्रयोगकर्ता लोड गर्दै...',
	'Current Network': 'वर्तमान संजाल',
	Networks: 'संजालहरु ',
	'Select an Network': 'एउटा संजाल छान्नुहोस्:',
	'Please note: changing network will show current balance of only the active network':
		'कृपया ध्यान दिनुहोस्: संजाल परिवर्तनले सक्रिय सन्जालको मात्र वर्तमान ब्यालेन्स देखाउनेछ',
	'Available Networks': 'उपलब्ध संजालहरु ',
	'Rumsan Network': 'Rumsan संजाल',
	'Rumsan Test Network': 'Rumsan परीक्षण संजाल',
	'Custom Network': 'अनुकूल संजाल',
	'Network updated successfully': 'संजाल सफलतापूर्वक अद्यावधिक गरियो'
};
