export const ACTIVITY_NP = {
	Activities: 'गतिविधिहरु',
	'Activity Questionare': 'गतिविधि प्रश्नावली',
	General: 'सामान्य',
	Recurring: 'पुनरावर्ती',
	'Submit Answers': 'जवाफ बुझाउनुहोस्',
	'Complete Phase': 'पूरा चरण',
	'Edit Activity': 'सम्पादन गतिविधि',
	'No Recurring Activities': 'आवर्ती गतिविधिहरु छैन',
	'Upload Picture': 'तस्वीर अपलोड गर्नुहोस्',
	'Select file to upload': 'अपलोड गर्न फाइल चयन गर्नुहोस्',
	'Take a Picture': 'तस्वीर लिनुहोस्',
	Browse: 'ब्राउज गर्नुहोस्',
	'Skip Upload': 'स्कुप  अपलोड',
	'Upload Farm Image': 'खेतको तस्विर अपलोड गर्नुहोस्',
	Upload: 'अपलोड'
};
