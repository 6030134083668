// export default PRODUCT_NP = {
// 	Maize: 'मकै'
// };

export const PRODUCT_NP = {
	Maize: 'मकै',
	detailLabel: {
		'Planting Period': 'बेर्ना वा विउ राेप्ने अवधि',
		'Maturity Period': 'विरूवा सारिसके पछिकाे परिपक्कता अवधि',
		'Seedling Preparation Time': 'बिरुवा / बेर्ना तयारी अवधि (दिनहरू)',
		'Harvesting Period': 'बाली भित्र्याउने',
		'Seed Rate Per Kattha (gm)': 'अावश्यक विउकाे मात्रा (ग्राम​​ प्रति कठ्ठा)',
		'Seed Rate Per Ropani (gms)': 'बीउ दर (ग्राम/रोपनी)',
		'Distance Between Plants (cms)': 'बेर्ना लगाउने दुरी (से.मि)',
		'Distance Between Rows (cms)': 'Distance between rows (cm)',
		'Production Capacity': 'Production Capacity'
	}
};
